@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?7sdt4e');
  src:  url('fonts/icomoon.eot?7sdt4e#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?7sdt4e') format('truetype'),
    url('fonts/icomoon.woff?7sdt4e') format('woff'),
    url('fonts/icomoon.svg?7sdt4e#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-publiste_teilen:before {
  content: "\e9cb";
  color: #17375d;
}
.icon-publliste:before {
  content: "\e9c8";
  color: #17375d;
}
.icon-forschdatliste:before {
  content: "\e9c9";
  color: #17375d;
}
.icon-schliessen_weiss:before {
  content: "\e9ca";
  color: #fff;
}
.icon-menuepunkt_aufgeklappt:before {
  content: "\e9c5";
  color: #003560;
}
.icon-menuepunkt_zugeklappt:before {
  content: "\e9c6";
  color: #003560;
}
.icon-pfeil_oben1 .path1:before {
  content: "\e9c1";
  color: rgb(218, 218, 218);
}
.icon-pfeil_oben1 .path2:before {
  content: "\e9c2";
  margin-left: -0.888671875em;
  color: rgb(0, 53, 96);
}
.icon-pfeil_unten1 .path1:before {
  content: "\e9c3";
  color: rgb(218, 218, 218);
}
.icon-pfeil_unten1 .path2:before {
  content: "\e9c4";
  margin-left: -0.888671875em;
  color: rgb(0, 53, 96);
}
.icon-schliessen .path1:before {
  content: "\e94e";
  color: rgb(174, 16, 16);
}
.icon-schliessen .path2:before {
  content: "\e94f";
  margin-left: -1.0791015625em;
  color: rgb(255, 255, 255);
}
.icon-suche1:before {
  content: "\e950";
  color: #17375d;
}
.icon-telefon1:before {
  content: "\e951";
  color: #17375d;
}
.icon-uebertragen:before {
  content: "\e952";
  color: #fff;
}
.icon-warnung1:before {
  content: "\e953";
  color: #c90606;
}
.icon-werkanlegen1:before {
  content: "\e954";
  color: #003560;
}
.icon-werkedurchsuchen1:before {
  content: "\e955";
  color: #17375d;
}
.icon-werkmelden1:before {
  content: "\e956";
  color: #003560;
}
.icon-loeschen:before {
  content: "\e957";
  color: #fff;
}
.icon-logorub1:before {
  content: "\e9c7";
  color: #17375d;
}
.icon-orcidverknpfen1 .path1:before {
  content: "\e958";
  color: rgb(0, 53, 96);
}
.icon-orcidverknpfen1 .path2:before {
  content: "\e959";
  margin-left: -1.4638671875em;
  color: rgb(166, 206, 57);
}
.icon-orcidverknpfen1 .path3:before {
  content: "\e95a";
  margin-left: -1.4638671875em;
  color: rgb(166, 206, 57);
}
.icon-organisationanlegen1:before {
  content: "\e95b";
  color: #003560;
}
.icon-organisationdurchsuchen1:before {
  content: "\e95c";
  color: #003560;
}
.icon-papierkorb1 .path1:before {
  content: "\e95d";
  color: rgb(174, 16, 16);
}
.icon-papierkorb1 .path2:before {
  content: "\e95e";
  margin-left: -0.8896484375em;
  color: rgb(255, 255, 255);
}
.icon-personenanlegen1:before {
  content: "\e95f";
  color: #003560;
}
.icon-personendurchsuchen1:before {
  content: "\e960";
  color: #003560;
}
.icon-pfeil_oben .path1:before {
  content: "\e961";
  color: rgb(218, 218, 218);
}
.icon-pfeil_oben .path2:before {
  content: "\e962";
  margin-left: -0.8896484375em;
  color: rgb(218, 218, 218);
}
.icon-pfeil_oben .path3:before {
  content: "\e963";
  margin-left: -0.8896484375em;
  color: rgb(0, 53, 96);
}
.icon-pfeil_unten .path1:before {
  content: "\e964";
  color: rgb(218, 218, 218);
}
.icon-pfeil_unten .path2:before {
  content: "\e965";
  margin-left: -0.8896484375em;
  color: rgb(218, 218, 218);
}
.icon-pfeil_unten .path3:before {
  content: "\e966";
  margin-left: -0.8896484375em;
  color: rgb(0, 53, 96);
}
.icon-plus1 .path1:before {
  content: "\e967";
  color: rgb(141, 174, 16);
}
.icon-plus1 .path2:before {
  content: "\e968";
  margin-left: -0.8896484375em;
  color: rgb(255, 255, 255);
}
.icon-publikationslisteeinbinden1 .path1:before {
  content: "\e969";
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path2:before {
  content: "\e96a";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path3:before {
  content: "\e96b";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path4:before {
  content: "\e96c";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path5:before {
  content: "\e96d";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path6:before {
  content: "\e96e";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path7:before {
  content: "\e96f";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path8:before {
  content: "\e970";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path9:before {
  content: "\e971";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path10:before {
  content: "\e972";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path11:before {
  content: "\e973";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden1 .path12:before {
  content: "\e974";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden1 .path13:before {
  content: "\e975";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden1 .path14:before {
  content: "\e976";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden1 .path15:before {
  content: "\e977";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden1 .path16:before {
  content: "\e978";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden1 .path17:before {
  content: "\e979";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden1 .path18:before {
  content: "\e97a";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden1 .path19:before {
  content: "\e97b";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden1 .path20:before {
  content: "\e97c";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden1 .path21:before {
  content: "\e97d";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden1 .path22:before {
  content: "\e97e";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path23:before {
  content: "\e97f";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path24:before {
  content: "\e980";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path25:before {
  content: "\e981";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path26:before {
  content: "\e982";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path27:before {
  content: "\e983";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path28:before {
  content: "\e984";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path29:before {
  content: "\e985";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path30:before {
  content: "\e986";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path31:before {
  content: "\e987";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden1 .path32:before {
  content: "\e988";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-alleloeschen .path1:before {
  content: "\e989";
  color: rgb(174, 16, 16);
}
.icon-alleloeschen .path2:before {
  content: "\e98a";
  margin-left: -5.7109375em;
  color: rgb(255, 255, 255);
}
.icon-alleloeschen .path3:before {
  content: "\e98b";
  margin-left: -5.7109375em;
  color: rgb(255, 255, 255);
}
.icon-alleloeschen .path4:before {
  content: "\e98c";
  margin-left: -5.7109375em;
  color: rgb(255, 255, 255);
}
.icon-alleloeschen .path5:before {
  content: "\e98d";
  margin-left: -5.7109375em;
  color: rgb(255, 255, 255);
}
.icon-alleloeschen .path6:before {
  content: "\e98e";
  margin-left: -5.7109375em;
  color: rgb(255, 255, 255);
}
.icon-alleloeschen .path7:before {
  content: "\e98f";
  margin-left: -5.7109375em;
  color: rgb(255, 255, 255);
}
.icon-alleloeschen .path8:before {
  content: "\e990";
  margin-left: -5.7109375em;
  color: rgb(255, 255, 255);
}
.icon-alleloeschen .path9:before {
  content: "\e991";
  margin-left: -5.7109375em;
  color: rgb(255, 255, 255);
}
.icon-alleloeschen .path10:before {
  content: "\e992";
  margin-left: -5.7109375em;
  color: rgb(255, 255, 255);
}
.icon-alleloeschen .path11:before {
  content: "\e993";
  margin-left: -5.7109375em;
  color: rgb(255, 255, 255);
}
.icon-alleloeschen .path12:before {
  content: "\e994";
  margin-left: -5.7109375em;
  color: rgb(255, 255, 255);
}
.icon-alleloeschen .path13:before {
  content: "\e995";
  margin-left: -5.7109375em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path1:before {
  content: "\e996";
  color: rgb(23, 55, 93);
}
.icon-alleuebertragen .path2:before {
  content: "\e997";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path3:before {
  content: "\e998";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path4:before {
  content: "\e999";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path5:before {
  content: "\e99a";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path6:before {
  content: "\e99b";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path7:before {
  content: "\e99c";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path8:before {
  content: "\e99d";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path9:before {
  content: "\e99e";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path10:before {
  content: "\e99f";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path11:before {
  content: "\e9a0";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path12:before {
  content: "\e9a1";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path13:before {
  content: "\e9a2";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path14:before {
  content: "\e9a3";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path15:before {
  content: "\e9a4";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-alleuebertragen .path16:before {
  content: "\e9a5";
  margin-left: -6.4736328125em;
  color: rgb(255, 255, 255);
}
.icon-anlegen_extern .path1:before {
  content: "\e9a6";
  color: rgb(141, 174, 16);
}
.icon-anlegen_extern .path2:before {
  content: "\e9a7";
  margin-left: -2.10546875em;
  color: rgb(255, 255, 255);
}
.icon-anlegen_extern .path3:before {
  content: "\e9a8";
  margin-left: -2.10546875em;
  color: rgb(255, 255, 255);
}
.icon-anlegen_extern .path4:before {
  content: "\e9a9";
  margin-left: -2.10546875em;
  color: rgb(255, 255, 255);
}
.icon-anlegen_extern .path5:before {
  content: "\e9aa";
  margin-left: -2.10546875em;
  color: rgb(255, 255, 255);
}
.icon-anlegen_extern .path6:before {
  content: "\e9ab";
  margin-left: -2.10546875em;
  color: rgb(255, 255, 255);
}
.icon-anlegen_intern .path1:before {
  content: "\e9ac";
  color: rgb(141, 174, 16);
}
.icon-anlegen_intern .path2:before {
  content: "\e9ad";
  margin-left: -1.619140625em;
  color: rgb(255, 255, 255);
}
.icon-anlegen_intern .path3:before {
  content: "\e9ae";
  margin-left: -1.619140625em;
  color: rgb(255, 255, 255);
}
.icon-anlegen_intern .path4:before {
  content: "\e9af";
  margin-left: -1.619140625em;
  color: rgb(255, 255, 255);
}
.icon-bestaetigung .path1:before {
  content: "\e9b0";
  color: rgb(141, 174, 16);
}
.icon-bestaetigung .path2:before {
  content: "\e9b1";
  margin-left: -1.0791015625em;
  color: rgb(255, 255, 255);
}
.icon-einbetten .path1:before {
  content: "\e9b2";
  color: rgb(23, 55, 93);
}
.icon-einbetten .path2:before {
  content: "\e9b3";
  margin-left: -1.0791015625em;
  color: none;
}
.icon-einbetten .path3:before {
  content: "\e9b4";
  margin-left: -1.0791015625em;
  color: rgb(255, 255, 255);
}
.icon-einbetten .path4:before {
  content: "\e9b5";
  margin-left: -1.0791015625em;
  color: rgb(255, 255, 255);
}
.icon-einbetten .path5:before {
  content: "\e9b6";
  margin-left: -1.0791015625em;
  color: rgb(255, 255, 255);
}
.icon-ansende_aktiv:before {
  content: "\e9b7";
  color: #17375d;
}
.icon-ansende_inaktiv:before {
  content: "\e9b8";
  color: #17375d;
}
.icon-checkbox_ausgewaehlt:before {
  content: "\e9b9";
  color: #dadada;
}
.icon-checkbox_leer:before {
  content: "\e9ba";
  color: #dadada;
}
.icon-links_aktiv:before {
  content: "\e9bb";
  color: #17375d;
}
.icon-links_inaktiv:before {
  content: "\e9bc";
  color: #17375d;
}
.icon-rechts_aktiv:before {
  content: "\e9bd";
  color: #17375d;
}
.icon-rechts_inaktiv:before {
  content: "\e9be";
  color: #17375d;
}
.icon-andenanfang_aktiv:before {
  content: "\e9bf";
  color: #17375d;
}
.icon-andenanfang_inaktiv:before {
  content: "\e9c0";
  color: #17375d;
}
.icon-intern:before {
  content: "\e94b";
  color: #fff;
}
.icon-extern:before {
  content: "\e94c";
  color: #fff;
}
.icon-icon-anlegen_extern:before {
  content: "\e946";
}
.icon-icon-anlegen_intern:before {
  content: "\e947";
}
.icon-icon-pfeil_oben:before {
  content: "\e948";
}
.icon-icon-pfeil_unten:before {
  content: "\e949";
}
.icon-papierkorb:before {
  content: "\e94a";
}
.icon-plus:before {
  content: "\e94d";
}
.icon-warnung:before {
  content: "\e904";
  color: #c90606;
}
.icon-personendurchsuchen:before {
  content: "\e902";
  color: #003560;
}
.icon-logorub:before {
  content: "\e900";
  color: #17375d;
}
.icon-werkedurchsuchen:before {
  content: "\e901";
  color: #17375d;
}
.icon-organisationdurchsuchen:before {
  content: "\e905";
  color: #003560;
}
.icon-personenanlegen:before {
  content: "\e908";
  color: #003560;
}
.icon-gruppendurchsuchen:before {
  content: "\e906";
  color: #003560;
}
.icon-werkanlegen:before {
  content: "\e907";
  color: #003560;
}
.icon-organisationanlegen:before {
  content: "\e909";
  color: #003560;
}
.icon-gruppenanlegen:before {
  content: "\e90a";
  color: #003560;
}
.icon-suche:before {
  content: "\e90b";
  color: #17375d;
}
.icon-werkmelden:before {
  content: "\e90c";
  color: #003560;
}
.icon-publikationslisteeinbinden .path1:before {
  content: "\e90d";
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path2:before {
  content: "\e90e";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path3:before {
  content: "\e90f";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path4:before {
  content: "\e910";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path5:before {
  content: "\e911";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path6:before {
  content: "\e912";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path7:before {
  content: "\e913";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path8:before {
  content: "\e914";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path9:before {
  content: "\e915";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path10:before {
  content: "\e916";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path11:before {
  content: "\e917";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden .path12:before {
  content: "\e918";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden .path13:before {
  content: "\e919";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden .path14:before {
  content: "\e91a";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden .path15:before {
  content: "\e91b";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden .path16:before {
  content: "\e91c";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden .path17:before {
  content: "\e91d";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden .path18:before {
  content: "\e91e";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden .path19:before {
  content: "\e91f";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden .path20:before {
  content: "\e920";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden .path21:before {
  content: "\e921";
  margin-left: -1.9638671875em;
  color: rgb(8, 42, 75);
}
.icon-publikationslisteeinbinden .path22:before {
  content: "\e922";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path23:before {
  content: "\e923";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path24:before {
  content: "\e924";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path25:before {
  content: "\e925";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path26:before {
  content: "\e926";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path27:before {
  content: "\e927";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path28:before {
  content: "\e928";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path29:before {
  content: "\e929";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path30:before {
  content: "\e92a";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path31:before {
  content: "\e92b";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-publikationslisteeinbinden .path32:before {
  content: "\e92c";
  margin-left: -1.9638671875em;
  color: rgb(0, 53, 96);
}
.icon-orcidverknpfen .path1:before {
  content: "\e92d";
  color: rgb(0, 53, 96);
}
.icon-orcidverknpfen .path2:before {
  content: "\e92e";
  margin-left: -1.4638671875em;
  color: rgb(166, 206, 57);
}
.icon-orcidverknpfen .path3:before {
  content: "\e92f";
  margin-left: -1.4638671875em;
  color: rgb(166, 206, 57);
}
.icon-email:before {
  content: "\e930";
  color: #17375d;
}
.icon-telefon:before {
  content: "\e931";
  color: #17375d;
}
.icon-info:before {
  content: "\e932";
  color: #707070;
}
.icon-bearbeiten .path1:before {
  content: "\e933";
  color: rgb(255, 255, 255);
}
.icon-bearbeiten .path2:before {
  content: "\e934";
  margin-left: -1.0791015625em;
  color: rgb(23, 55, 93);
}
.icon-bearbeiten .path3:before {
  content: "\e935";
  margin-left: -1.0791015625em;
  color: rgb(23, 55, 93);
}
.icon-bearbeiten .path4:before {
  content: "\e903";
  margin-left: -1.0791015625em;
  color: rgb(23, 55, 93);
}
.icon-anmelden:before {
  content: "\e936";
  color: #003560;
}
.icon-pfeil_suche_aufgeklappt:before {
  content: "\e937";
  color: #003560;
}
.icon-pfeil_menueband_aufgeklappt:before {
  content: "\e938";
  color: #003560;
}
.icon-pfeil_suche_zugeklappt:before {
  content: "\e939";
  color: #003560;
}
.icon-werkeanzeigen .path1:before {
  content: "\e93a";
  color: rgb(255, 255, 255);
}
.icon-werkeanzeigen .path2:before {
  content: "\e93b";
  margin-left: -1.078125em;
  color: rgb(255, 255, 255);
}
.icon-werkeanzeigen .path3:before {
  content: "\e93c";
  margin-left: -1.078125em;
  color: rgb(0, 53, 96);
}
.icon-werkeanzeigen .path4:before {
  content: "\e93d";
  margin-left: -1.078125em;
  color: rgb(0, 53, 96);
}
.icon-werkeanzeigen .path5:before {
  content: "\e93e";
  margin-left: -1.078125em;
  color: rgb(0, 53, 96);
}
.icon-werkeanzeigen .path6:before {
  content: "\e93f";
  margin-left: -1.078125em;
  color: rgb(0, 53, 96);
}
.icon-werkeanzeigen .path7:before {
  content: "\e940";
  margin-left: -1.078125em;
  color: rgb(0, 53, 96);
}
.icon-werkeanzeigen .path8:before {
  content: "\e941";
  margin-left: -1.078125em;
  color: rgb(0, 53, 96);
}
.icon-zitieren_weiss:before {
  content: "\e942";
  color: #fff;
}
.icon-zitieren_blau:before {
  content: "\e943";
  color: #003560;
}
.icon-papierkorb_weiss:before {
  content: "\e944";
  color: #fff;
}
.icon-papierkorb_blau:before {
  content: "\e945";
  color: #003560;
}
