@font-face {
  font-family: 'RUB Nepo Icons';
  src: url(../local_fonts/RUB_Nepo_Icons-normal-normal/rub_newsportal_icons_abc.eot);
  src: url(../local_fonts/RUB_Nepo_Icons-normal-normal/rub_newsportal_icons_abc.eot?#iefix) format('embedded-opentype'),
    url(../local_fonts/RUB_Nepo_Icons-normal-normal/rub_newsportal_icons_abc.ttf) format('truetype'),
    url(../local_fonts/RUB_Nepo_Icons-normal-normal/rub_newsportal_icons_abc.woff) format('woff'),
    url(../local_fonts/RUB_Nepo_Icons-normal-normal/rub_newsportal_icons_abc.svg#rub_newsportal_icons_abc) format('svg');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Flama';
  src: url(../local_fonts/RUB_Neu_Flama-normal-bold/rubflama-bold.eot);
  src: url(../local_fonts/RUB_Neu_Flama-normal-bold/rubflama-bold.eot?#iefix) format('embedded-opentype'),
    url(../local_fonts/RUB_Neu_Flama-normal-bold/rubflama-bold.ttf) format('truetype'),
    url(../local_fonts/RUB_Neu_Flama-normal-bold/rubflama-bold.woff) format('woff'),
    url(../local_fonts/RUB_Neu_Flama-normal-bold/rubflama-bold.svg#RubFlama-Bold) format('svg');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Flama';
  src: url(../local_fonts/RUB_Neu_Flama-normal-normal/flama-basic-a.eot);
  src: url(../local_fonts/RUB_Neu_Flama-normal-normal/flama-basic-a.eot?#iefix) format('embedded-opentype'),
    url(../local_fonts/RUB_Neu_Flama-normal-normal/flama-basic-a.ttf) format('truetype'),
    url(../local_fonts/RUB_Neu_Flama-normal-normal/flama-basic-a.woff) format('woff'),
    url(../local_fonts/RUB_Neu_Flama-normal-normal/flama-basic-a.svg#Flama-Basic) format('svg');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Scala';
  src: url(../local_fonts/RUB_Neu_Scala-italic-bold/0a2caf4c-c387-4017-b49a-ff310c9b904a.eot);
  src: url(../local_fonts/RUB_Neu_Scala-italic-bold/0a2caf4c-c387-4017-b49a-ff310c9b904a.eot?#iefix)
      format('embedded-opentype'),
    url(../local_fonts/RUB_Neu_Scala-italic-bold/3be292a2-4a09-4da9-a6d0-b49bc9bd1416.ttf) format('truetype'),
    url(../local_fonts/RUB_Neu_Scala-italic-bold/dd5daa4e-14c2-42a9-838e-73fa41ea9044.woff) format('woff'),
    url(../local_fonts/RUB_Neu_Scala-italic-bold/0af5367d-999c-4bc0-9bad-9422140ca749.svg#0af5367d-999c-4bc0-9bad-9422140ca749)
      format('svg');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'Scala';
  src: url(../local_fonts/RUB_Neu_Scala-italic-normal/d6334097-85f7-45a7-b236-61fde24453f4.eot);
  src: url(../local_fonts/RUB_Neu_Scala-italic-normal/d6334097-85f7-45a7-b236-61fde24453f4.eot?#iefix)
      format('embedded-opentype'),
    url(../local_fonts/RUB_Neu_Scala-italic-normal/d1d81415-bffa-4516-a833-9e545abc685c.ttf) format('truetype'),
    url(../local_fonts/RUB_Neu_Scala-italic-normal/3fe779fd-47cd-4f65-8e79-523e6106a73c.woff) format('woff'),
    url(../local_fonts/RUB_Neu_Scala-italic-normal/95fba401-19ec-44f6-b568-5cad124a3f73.svg#95fba401-19ec-44f6-b568-5cad124a3f73)
      format('svg');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'Scala';
  src: url(../local_fonts/RUB_Neu_Scala-normal-bold/0e076fbb-4139-4d5e-8bc0-8bf5a90766e0.eot);
  src: url(../local_fonts/RUB_Neu_Scala-normal-bold/0e076fbb-4139-4d5e-8bc0-8bf5a90766e0.eot?#iefix)
      format('embedded-opentype'),
    url(../local_fonts/RUB_Neu_Scala-normal-bold/affe177e-580a-4dad-8333-dee8db15c368.ttf) format('truetype'),
    url(../local_fonts/RUB_Neu_Scala-normal-bold/06807d16-017f-4d3d-afb1-1f97c0150427.woff) format('woff'),
    url(../local_fonts/RUB_Neu_Scala-normal-bold/d6e16d44-3e92-437e-9f02-6f3ca07c3a96.svg#d6e16d44-3e92-437e-9f02-6f3ca07c3a96)
      format('svg');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Scala';
  src: url(../local_fonts/RUB_Neu_Scala-normal-normal/179f9213-a414-4fd1-914a-8e7b8f2a24d2.eot);
  src: url(../local_fonts/RUB_Neu_Scala-normal-normal/179f9213-a414-4fd1-914a-8e7b8f2a24d2.eot?#iefix)
      format('embedded-opentype'),
    url(../local_fonts/RUB_Neu_Scala-normal-normal/402d489d-8605-4109-911f-c2fe749c5210.ttf) format('truetype'),
    url(../local_fonts/RUB_Neu_Scala-normal-normal/556c8792-4efa-40d8-9944-3ce48e9edc1b.woff) format('woff'),
    url(../local_fonts/RUB_Neu_Scala-normal-normal/c2f4564c-1e6b-499c-875f-0e56eb9f6c35.svg#c2f4564c-1e6b-499c-875f-0e56eb9f6c35)
      format('svg');
  font-style: normal;
  font-weight: normal;
}

.rub-icons {
  display: inline-block;
  font-family: 'RUB Nepo Icons', Arial, Helvetica, sans-serif;
  font-style: normal;
}
.rub-icons.person:before {
  content: '\0041';
}
.rub-icons.chart:before {
  content: '\0042';
}
.rub-icons.option:before {
  content: '\0043';
}
.rub-icons.mail:before {
  content: '\0044';
}
.rub-icons.youtube:before {
  content: '\0045';
}
.rub-icons.whatsapp:before {
  content: '\0046';
}
.rub-icons.instagram:before {
  content: '\0047';
}
.rub-icons.twitter:before {
  content: '\0048';
}
.rub-icons.facebook:before {
  content: '\0049';
}
.rub-icons.alphabet:before {
  content: '\004A';
}
.rub-icons.rss:before {
  content: '\004B';
}
.rub-icons.swipe:before {
  content: '\004C';
}
.rub-icons.pencil:before {
  content: '\004D';
}
.rub-icons.darkmode:before {
  content: '\004E';
}
.rub-icons.search:before {
  content: '\004F';
}
.rub-icons.list:before {
  content: '\0050';
}
.rub-icons.calendar:before {
  content: '\0051';
}
.rub-icons.message:before {
  content: '\0052';
}
.rub-icons.language:before {
  content: '\0053';
}
.rub-icons.person-info:before {
  content: '\0054';
}
.rub-icons.side-menu:before {
  content: '\0061';
}
.rub-icons.cancel:before {
  content: '\0062';
}
.rub-icons.down:before {
  content: '\0063';
}
.rub-icons.up:before {
  content: '\0064';
}
.rub-icons.left:before {
  content: '\0065';
}
.rub-icons.right:before {
  content: '\0066';
}
.rub-icons.document:before {
  content: '\0067';
}
.rub-icons.persons:before {
  content: '\0068';
}
.rub-icons.bag:before {
  content: '\0069';
}
.rub-icons.ball:before {
  content: '\006A';
}
.rub-icons.hat:before {
  content: '\006B';
}
.rub-icons.science:before {
  content: '\006C';
}
.rub-icons.art:before {
  content: '\006D';
}
.rub-icons.pylon:before {
  content: '\006E';
}
.rub-icons.send:before {
  content: '\006F';
}
.rub-icons.files:before {
  content: '\0070';
}
.rub-icons.folder:before {
  content: '\0071';
}
.rub-icons.book:before {
  content: '\0072';
}
.rub-icons.camera:before {
  content: '\0073';
}
.rub-icons.papers:before {
  content: '\0074';
}
.rub-icons.list2:before {
  content: '\0075';
}
.rub-icons.side-menu2:before {
  content: '\0076';
}
.rub-icons.download:before {
  content: '\0077';
}
.rub-icons.info:before {
  content: '\0078';
}
.rub-icons.arrow:before {
  content: '\0079';
}
.rub-icons.share:before {
  content: '\007A';
}
