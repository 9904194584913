@font-face {
  font-family: 'Titillium';
  font-style: normal;
  font-weight: 400;
  src: url('titillium-regular.ttf') format('truetype'), url('titillium-regular.woff') format('woff');
}
@font-face {
  font-family: 'Titillium';
  font-style: normal;
  font-weight: bold;
  src: url('titillium-bold.ttf') format('truetype'), url('titillium-bold.woff') format('woff');
}
@font-face {
  font-family: 'Titillium';
  font-style: italic;
  font-weight: 400;
  src: url('titillium-italic.ttf') format('truetype'), url('titillium-italic.woff') format('woff');
}
@font-face {
  font-family: 'Titillium';
  font-style: normal;
  font-weight: 100;
  src: url('titillium-light.ttf') format('truetype'), url('titillium-light.woff') format('woff');
}
